import React from "react"
import Layout from "../components/layout"

type EventsPageProps = {
  location: Location
}

const EventsPage: React.FC<EventsPageProps> = ({ location }) => {
  return (
    <Layout location={location} title="Grease">
      <h1>Events</h1>
      <p style={{ marginTop: "20px", fontSize: "1.2rem", color: "#555" }}>
        There are no current events scheduled. Check back soon for updates!
      </p>
    </Layout>
  )
}

export default EventsPage
